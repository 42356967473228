<template>
  <el-dialog
    title="选择项目"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="60%"
    class="qmDialog selectQuoteProject"
    @close="closeDialog"
  >
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>

    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @gird-handle-select-click="selectionChange"
    >
      <template slot="birthday" slot-scope="{ row }">
        <span>
          {{ row.idCoding && row.idCoding != "" ? getAge(row.idCoding) : "" }}
        </span>
      </template>
      <!-- <template #customBtn="{ row }">
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowSelect(row)"
          >选择
        </el-button>
      </template> -->
    </grid-layout>
    <template slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="handleSave"
        v-loading="dialogLoading"
      >
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { quoteProjectPage, quoteProjectSubmit } from "@/api/safe/itemList";
import { getAgeFromIdCard } from "@/util/util";
import { dateFormat } from "@/util/date";
import dayjs from "dayjs";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        {
          label: "项目名称",
          prop: "name",
          span: 4,
          placeholder: "项目名称",
        },
        {
          label: "项目编码",
          prop: "code",
          span: 4,
          placeholder: "项目编码",
        },
        {
          label: "区域名称",
          prop: "areaName",
          span: 4,
          placeholder: "区域名称",
        },
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: true,
        menu: false,
        column: [
          {
            label: "项目名称",
            prop: "name",
            align: "center",
            overHidden: true,
          },
          {
            label: "项目编码",
            prop: "code",
            align: "center",
            overHidden: true,
          },
          {
            label: "境内外",
            prop: "projectArea",
            align: "center",
            overHidden: true,
            // dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_domestic`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "区域名称",
            prop: "areaName",
            align: "center",
            overHidden: true,
          },
          {
            label: "区域编码",
            prop: "areaCode",
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableData: [],
      query: {},
      form: {
        list: [],
      },
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  methods: {
    getAge(data) {
      return getAgeFromIdCard(data);
    },
    init(query, contractId) {
      this.contractId = contractId;
      this.page.currentPage = 1;
      this.page.pageSize = 20;
      this.query = query;
      this.showDialog = true;
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      quoteProjectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    // 选择公司
    rowSelect(row) {},
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    handleSave() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning("请选择项目");
        return;
      }
      // console.log('list=>',selectionList)
      let paramsArr = [];
      paramsArr = selectionList.map((item) => {
        return {
          latitude: item.latitude,
          longitude: item.longitude,
          prjName: item.name,
          prjCode: item.code,
          prjAre: item.areaCode,
          areName: item.areaName,
          responsibleUnitCode: item.responsibleUnitCode,
          responsibleUnitName: item.responsibleUnitName,
          prjJoinDate: dateFormat(new Date(), "yyyy-MM-dd"),
          pmCode: item.pmCode,
          pmCame: item.pmName,
          isDomestic: item.projectArea,
          prjDate: dayjs(item.regDate).isValid()
            ? dayjs(item.regDate).format("YYYY-MM-DD")
            : "",
          prjState: item.status,
        };
      });
      // console.log('arr',paramsArr)
      quoteProjectSubmit(paramsArr, this.contractId).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("操作成功");
          this.closeDialog();
        }
      });

      // this.$emit("callback", row);
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
      this.$emit("callback");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.selectQuoteProject .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
