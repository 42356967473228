var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _vm.userInfo.deptCategory != 5
        ? _c(
            "el-container",
            [
              _c("CommonTree", {
                ref: "commonTreeCore",
                attrs: {
                  treeTitle: "组织列表",
                  treeData: _vm.treeData,
                  defaultProps: _vm.defaultProps,
                  isShowdig: false,
                  showCheckbox: false,
                  treeExpand: false,
                  isZoom: true,
                  show: _vm.show,
                  "node-key": "id",
                },
                on: {
                  getNodeClick: _vm.treeNodeClick,
                  showChange: _vm.showChange,
                },
              }),
              _c(
                "el-main",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-title": "项目列表",
                      "head-btn-options": _vm.headBtnOptions,
                    },
                    on: {
                      "head-add": _vm.handleExport,
                      "head-quote-project": _vm.handleQuoteProject,
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: { "search-columns": _vm.searchColumns },
                    on: {
                      "grid-head-search": _vm.gridHeadSearch,
                      "grid-head-empty": _vm.gridHeadEmpty,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayOut",
                    attrs: {
                      "table-options": _vm.tableOption,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.tableLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                    },
                    on: {
                      "grid-row-detail-click": _vm.rowEdit,
                      "gird-handle-select-click": _vm.selectionChange,
                      "page-current-change": _vm.onLoad,
                      "page-size-change": _vm.onLoad,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              _vm.isIndex
                                ? _c(
                                    "div",
                                    [
                                      row.prjState !== 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: { margin: "0 3px" },
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rowEdit(
                                                    row,
                                                    "edit"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑\n            ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { margin: "0 3px" },
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowClose(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.prjState === 0
                                                ? "执行"
                                                : "关闭"
                                            ) + "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isIndex
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectRow(row)
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      381579637
                    ),
                  }),
                ],
                1
              ),
              _c("selectQuoteProject", {
                ref: "selectQuoteProject",
                on: { callback: _vm.quoteProjectCallback },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }